import logo from "./logo.svg";
import "@fontsource/inter";
import "./App.css";

import {
  Box,
  ChakraProvider,
  Flex,
  HStack,
  Link,
  VStack,
} from "@chakra-ui/react";
import Founder from "./Founder";
import DarkModeProvider from "./DarkModeProvider";

function App() {
  return (
    <ChakraProvider>
      <DarkModeProvider>
        <Box height={"100vh"} className="App">
          <Flex
            width={"100vw"}
            height={"100vh"}
            alignContent={"center"}
            justifyContent={"center"}
          >
            <VStack>
              <HStack>
                <Founder
                  className="founder"
                  name="ajay"
                  linkedinUrl={"https://www.linkedin.com/in/ajaynraj"}
                />
                <Founder
                  className="founder"
                  name="kian"
                  linkedinUrl={"https://www.linkedin.com/in/kianhooshmand/"}
                />
              </HStack>
              <Link href="https://github.com/vocodedev">🤖 vocode</Link>
              <Link href="mailto:kian@the1388.company">
                ✉️ Email for support
              </Link>
            </VStack>
          </Flex>
        </Box>
      </DarkModeProvider>
    </ChakraProvider>
  );
}

export default App;
